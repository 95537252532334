<template>
  <div class="card card-body shadow-sm infomations-list">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.INFORMATION_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row" style="justify-content: space-between;">
          <div class="col-xl-6 col-lg-6 col-sm-6">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.status_publishing_equal.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck">
                <app-radio v-for="(item, index) in INFORMATION_CONSTANT.filter_status"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="filters.status_publishing_equal.value"/>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-6">
            <div class="form-group ">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.information_type_equal.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck">
                <app-radio v-for="(item, index) in INFORMATION_CONSTANT.filter_type"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="filters.information_type_equal.value"/>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.keyword_like.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-input :name="filters.keyword_like.name" input-style="normal"
                           v-model="filters.keyword_like.value"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-4 col-lg-12 col-sm-8">{{ filters.apartment_id_equal.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12">
                <app-select :name="filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="filters.apartment_id_equal.value"
                            :options-data="meta.apartments" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>


      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{getApartmentName(props.row.apartment_id)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-information_type="props">
        <td class="app-align-middle">
          <p :data-original-title="getInformationType(props.row.information_type)" class="app-table-p app-cell-tooltip mb-0">
            {{getInformationType(props.row.information_type)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-status_publishing="props">
        <td class="app-align-middle">
          <p class=" app-table-p app-cell-tooltip mb-0">
            {{getStatusPublising(props.row.status_publishing)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image_path" v-if="props.row.image_path" style="width: 100px; height: 100px">
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  export default {
    name: "InformationList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          status_publishing_equal: {
            name: "status_publishing",
            condition: "equal",
            label: this.$t("information.status")
          },
          information_type_equal: {
            name: "infor_type",
            condition: "equal",
            label: this.$t("information.filter_type")
          },
          keyword_like: {
            name: "keyword",
            condition: "like",
            label: this.$t("information.keyword")
          },
          from_date_between : {
            name: "from_date",
            condition: "between",
            label: this.$t("information.date")
          },
          to_date_between : {
            name: "to_date",
            condition: "between",
            label: this.$t("information.date")
          },
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
        },
        columns: [
          {name: "display_order", label: this.$t("common.number_no"), sortable: true, textAlign: 'text-center'},
          {name: "information_type", label: this.$t("information.type_list"), sortable: true, textAlign: 'text-center'},
          {name: "title", label: this.$t("information.title"), sortable: true},
          {name: "image_path", label: this.$t("information.image"), textAlign: 'text-center'},
          {name: "description", label: this.$t("information.description")},
          {name: "created_at_full_time", label: this.$t("information.created_at"), sortable: true, textAlign: 'text-center'},
          {name: "status_publishing", label: this.$t("information.status"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("information.mansion"), sortable: true, textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch : {
      "filters.from_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.INFORMATION_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.INFORMATION_EDIT, params: {id: entry.id}})
      },
      handleBtnManageStatus(entry) {

        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_MANAGE_STATUS, params: {id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getApartmentName(apartmentId) {
        let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
        if (apartment == undefined) {
          return '';
        }
        return apartment.name;
      },
      getInformationType(typeID) {
        let type = INFORMATION_CONSTANT.types.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      getStatusPublising(typeID) {
        let type = INFORMATION_CONSTANT.status.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
    },
    mounted: function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id: "",
          name: ""
        })
      })
    },
  }
</script>
